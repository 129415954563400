import { defineRoute } from "../utils/routing/routing";
import * as t from "io-ts";
import { subdomainCodec } from "../utils/subdomain/subdomain";
import { subdomainsDir } from "../const";
import { NumberFromString } from "io-ts-types";
import { events, raceTable, segmentTable } from "../domain/event";
import { rankingModeCodec, seriesTable } from "../domain/series";

const base = `${subdomainsDir}/[subdomain]`;

const orgQueryBase = {
    subdomain: subdomainCodec,
};
const subdomainOnlyQueryCodec = t.type(orgQueryBase);

export type SubdomainQuery = t.TypeOf<typeof subdomainOnlyQueryCodec>;

export const index = defineRoute(subdomainOnlyQueryCodec, (query) => ({
    pathname: base,
    query,
}));

export const contact = defineRoute(subdomainOnlyQueryCodec, (query) => ({
    pathname: `${base}/contact`,
    query,
}));

export const calendar = defineRoute(subdomainOnlyQueryCodec, (query) => ({
    pathname: `${base}/calendar`,
    query,
}));

// TODO replace with course, repeated event? https://github.com/pompep/xathlo/issues/38
export const segmentDetailRoute = defineRoute(
    t.type({
        ...orgQueryBase,
        segmentId: NumberFromString.pipe(segmentTable.columns.id),
    }),
    (query) => ({
        pathname: `${base}/segment/[segmentId]`,
        query,
    })
);

export const rules = defineRoute(subdomainOnlyQueryCodec, (query) => ({
    pathname: `${base}/rules`,
    query,
}));

export const history = defineRoute(subdomainOnlyQueryCodec, (query) => ({
    pathname: `${base}/history`,
    query,
}));

const eventQueryCodec = t.type({
    ...orgQueryBase,
    eventId: NumberFromString.pipe(events.columns.id),
});
const eventBasePath = `${base}/event/[eventId]`;

export const eventRoute = defineRoute(eventQueryCodec, (query) => ({
    pathname: eventBasePath,
    query,
}));

export const newsRoute = defineRoute(subdomainOnlyQueryCodec, (query) => ({
    pathname: `${base}/news`,
    query,
}));

export const resultsRoute = defineRoute(
    t.intersection([
        eventQueryCodec,
        t.partial({
            raceId: NumberFromString.pipe(raceTable.columns.id),
        }),
    ]),
    (query) => ({
        pathname: `${eventBasePath}/results`,
        query,
    })
);

export const liveResultsRoute = defineRoute(
    t.intersection([
        eventQueryCodec,
        t.partial({
            raceId: NumberFromString.pipe(raceTable.columns.id),
            club: t.string,
        }),
    ]),
    (query) => ({
        pathname: `${eventBasePath}/live`,
        query,
    })
);

const seriesBasePath = `${base}/series`;
export const seriesListRoute = defineRoute(t.type(orgQueryBase), (query) => ({
    pathname: seriesBasePath,
    query,
}));

export const seriesDetailRoute = defineRoute(
    t.intersection([
        t.type({
            ...orgQueryBase,
            seriesId: NumberFromString.pipe(seriesTable.columns.id),
        }),
        t.partial({ mode: rankingModeCodec }),
    ]),
    (query) => ({
        pathname: `${seriesBasePath}/[seriesId]`,
        query,
    })
);
