import * as React from "react";
import Image, { StaticImageData } from "next/legacy/image";
import jvsLogo from "../../../public/jesenickysnek/JVS.png";
import fitkoLogo from "../../../public/jesenickysnek/fitko.png";
import jesenikLogo from "../../../public/jesenickysnek/jesenik.svg";
import kofolaLogo from "../../../public/jesenickysnek/kofola.png";
import partnersLogo from "../../../public/jesenickysnek/partners.png";
import stapromLogo from "../../../public/jesenickysnek/staprom.png";
import knotekLogo from "../../../public/jesenickysnek/knotek.png";
import euLogo from "../../../public/jesenickysnek/eu.jpg";
import olKrajLogo from "../../../public/feje/olKraj.jpg";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import {
    AppBar,
    Box,
    Container,
    Grid,
    Hidden,
    IconButton,
    Link,
    ThemeProvider,
    Toolbar,
    Typography,
} from "@mui/material";
import { UrlObject } from "url";
import {
    calendar,
    contact,
    index,
    rules,
    seriesListRoute,
    SubdomainQuery,
} from "../../routes/org";
import { ExternalLink, InternalLink } from "../common/Link";
import { LogoXathlo } from "../LogoXathlo";
import { NextSeoProps } from "next-seo/lib/types";
import { NextSeo } from "next-seo";
import { createXathloTheme } from "../../theme/light";

interface Props extends SubdomainQuery {
    children: React.ReactNode;
    seo?: NextSeoProps;
}

const snekTheme = createXathloTheme({
    palette: {
        primary: {
            main: "#008983",
            light: "#4eb9b2",
            dark: "#005b56",
        },
    },
});

const Layout = (p: Props) => {
    return (
        <ThemeProvider theme={snekTheme}>
            {p.seo == null ? null : <NextSeo {...p.seo} />}
            <Box
                sx={{
                    // backgroundColor: (theme) => theme.palette.grey[200],
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                }}
            >
                <AppBar
                    position="static"
                    color="transparent"
                    elevation={4}
                    sx={{
                        pt: 1,
                    }}
                >
                    <Container maxWidth={"lg"}>
                        <Toolbar
                            sx={{
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box>
                                <InternalLink
                                    href={index.route({
                                        subdomain: p.subdomain,
                                    })}
                                >
                                    <Link>
                                        <img
                                            width={325}
                                            height={78}
                                            alt={
                                                "Jesenický šnek, seriál cyklistických závodů"
                                            }
                                            src={"/jesenickysnek/logo.svg"}
                                        />
                                    </Link>
                                </InternalLink>
                            </Box>

                            <Grid
                                container
                                component={"nav"}
                                sx={{ width: "auto", pt: 1, pb: 2 }}
                                spacing={3}
                            >
                                <NavLink
                                    label={"Kalendář"}
                                    route={calendar.route({
                                        subdomain: p.subdomain,
                                    })}
                                />
                                <NavLink
                                    label={"Žebříček"}
                                    route={seriesListRoute.route({
                                        subdomain: p.subdomain,
                                    })}
                                />
                                <NavLink
                                    label={"Pravidla"}
                                    route={rules.route({
                                        subdomain: p.subdomain,
                                    })}
                                />
                                <NavLink
                                    label={"Kontakty"}
                                    route={contact.route({
                                        subdomain: p.subdomain,
                                    })}
                                />
                                <NavItem>
                                    <Link
                                        href="https://api.xathlo.com/storage/v1/object/public/org-1/snek2024.pdf"
                                        target={"_blank"}
                                        variant="button"
                                        underline={"hover"}
                                    >
                                        plakát 2024
                                    </Link>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*    <Link*/}
                                {/*        href="http://jesenickysnek.com"*/}
                                {/*        target={"_blank"}*/}
                                {/*        variant="button"*/}
                                {/*        underline={"hover"}*/}
                                {/*    >*/}
                                {/*        Historie*/}
                                {/*    </Link>*/}
                                {/*</NavItem>*/}
                                {/*<NavLink*/}
                                {/*    label={"O seriálu"}*/}
                                {/*    route={history.route({*/}
                                {/*        subdomain: p.subdomain,*/}
                                {/*    })}*/}
                                {/*/>*/}
                                {/*<NavLink label={"Archiv výsledků"} />*/}
                            </Grid>
                            <Hidden mdDown>
                                <Box>
                                    <IconButton
                                        target={"_blank"}
                                        href={
                                            "https://www.facebook.com/jesenickysnek"
                                        }
                                    >
                                        <FacebookIcon />
                                    </IconButton>
                                    <IconButton
                                        target={"_blank"}
                                        href={
                                            "https://www.instagram.com/jesenickysnek/"
                                        }
                                    >
                                        <InstagramIcon />
                                    </IconButton>
                                </Box>
                            </Hidden>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Box
                    sx={{
                        my: 4,
                    }}
                >
                    {p.children}
                </Box>
                <Box
                    component="footer"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.background.default,
                        marginTop: "auto",
                        p: 2,
                        borderTop: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Container maxWidth="xl">
                        <Typography variant={"h5"}>Partneři:</Typography>
                        <Grid
                            container
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            spacing={1}
                        >
                            <Partner
                                logo={jvsLogo}
                                web={"https://www.voda-jvs.cz/"}
                                name={
                                    "Jesenická vodohospodářská společnost , s.r.o."
                                }
                                height={70}
                            />
                            <Partner
                                logo={fitkoLogo}
                                web={"https://www.fitko-jesenik.cz/"}
                                name={"FIT:KO Jeseník"}
                                height={90}
                            />
                            <Partner
                                logo={kofolaLogo}
                                web={"https://www.kofola.cz/"}
                                name={"Kofola ČeskoSlovensko a.s."}
                                height={120}
                            />
                            <Partner
                                logo={jesenikLogo}
                                web={"https://www.jesenik.org/"}
                                name={"Město Jeseník"}
                                height={70}
                            />
                            <Partner
                                logo={olKrajLogo}
                                web={"https://www.olkraj.cz/"}
                                name={"Olomoucký kraj"}
                                height={100}
                            />
                            <Partner
                                logo={partnersLogo}
                                web={"https://www.partners.cz/"}
                                name={"partners"}
                                height={110}
                            />
                            <Partner
                                logo={stapromLogo}
                                web={"http://www.staprom.cz/"}
                                name={"STAPROM"}
                                height={120}
                            />
                            <Partner
                                logo={knotekLogo}
                                web={"http://www.stavitelstviknotek.cz/"}
                                name={"Stavitelství Knotek"}
                                height={120}
                            />
                            <Partner
                                logo={euLogo}
                                web={
                                    "https://wcojvdztpvjycomporgi.supabase.co/storage/v1/object/public/org-1/SZIF.pdf"
                                }
                                name={"podpora MAS"}
                                height={60}
                            />
                            <PartnerGridItem>
                                <ExternalLink href={"https://xathlo.com"}>
                                    <LogoXathlo />
                                </ExternalLink>
                            </PartnerGridItem>
                        </Grid>
                    </Container>
                </Box>
            </Box>

            <script
                defer
                src="https://static.cloudflareinsights.com/beacon.min.js"
                data-cf-beacon='{"token": "a39fec39fa8d4b22a73efe509f7a8cde"}' // TODO make customizable per domain (cloudflare api?)
            />
        </ThemeProvider>
    );
};

export default Layout;

interface PartnerProps {
    logo: StaticImageData;
    web: string;
    name: string;
    height?: number;
}

interface Rect {
    width: number;
    height: number;
}

const Partner = (p: PartnerProps) => {
    const imageSize: Partial<Rect> = pipe(
        option.fromNullable(p.height),
        option.fold(
            () => p.logo,
            (h) => {
                const w2h = p.logo.width / p.logo.height;
                const width = w2h * h;
                return { width, height: p.height };
            }
        )
    );

    return (
        <PartnerGridItem>
            <ExternalLink href={p.web}>
                <Image
                    src={p.logo}
                    alt={p.name}
                    width={imageSize.width}
                    height={imageSize.height}
                />
            </ExternalLink>
        </PartnerGridItem>
    );
};

interface PartnerGridItemProps {
    children: React.ReactNode;
}

const PartnerGridItem = (p: PartnerGridItemProps) => {
    return (
        <Grid
            item
            xs={12}
            sm={3}
            md={2}
            lg={1}
            flexGrow={1}
            sx={{ textAlign: "center" }}
        >
            {p.children}
        </Grid>
    );
};

interface NavLinkProps {
    label: string;
    route: UrlObject;
}

const NavLink = (p: NavLinkProps) => (
    <NavItem>
        <InternalLink href={p.route}>
            <Link variant="button" underline={"hover"}>
                {p.label}
            </Link>
        </InternalLink>
    </NavItem>
);

interface NavItemProps {
    children: React.ReactNode;
}

const NavItem = (props: NavItemProps) => <Grid item>{props.children}</Grid>;
